<template>
  <CustomFullCalendar :events="events"></CustomFullCalendar>
</template>

<script>

import CustomFullCalendar from '@/components/CustomFullCalendar';

export default {
  name: 'test',
  components: {
    'CustomFullCalendar': CustomFullCalendar,
  },
  data: () => ({
    events: [
      {
        title: "event 1",
        times: [
          {
            start: "2021-09-13T00:00:00",
            end: "2021-09-17T23:59:59",
          },
          {
            start: "2021-09-18T00:00:00",
            end: "2021-09-19T23:59:59",
          },
          {
            start: "2021-09-26T00:00:00",
            end: "2021-09-28T23:59:59",
          },
          {
            start: "2021-09-30T00:00:00",
            end: "2021-10-05T23:59:59",
          }
        ],
      },
      {
        title: "event 2",
        times: [
          {
            start: "2021-10-13T09:00:00",
            end: "2021-10-15T23:00:00",
          }
        ],
      },
      {
        title: "event 3",
        times: [
          {
            start: "2021-09-21T12:30:00",
            end: "2021-09-22T06:45:00",
          }
        ],
      },
      {
        title: "event 4",
        times: [
          {
            start: "2021-09-10T07:30:00",
            end: "2021-09-15T09:30:00",
          }
        ],
      },
      // {"title":"event 5","start":"2021-09-25T10:00:00","end":"2021-09-25T15:00:00"}
    ],
  })
}
</script>

<style scoped>

</style>